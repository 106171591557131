
//equlaize columns
function bindEqualColumns() {
    var groupNames = [];

    $('[data-column-group]').each(function () {
        var groupName = $(this).data('columnGroup');

        if (groupNames.indexOf(groupName) == -1) {
            groupNames.push(groupName);
        }
    });

    for (var i = 0; i < groupNames.length; i++) {
        $('[data-column-group=' + groupNames[i] + ']').equalHeightColumns({
            minWidth: 768
        });
    }
}

bindEqualColumns();

$('input').placeholder();



console.log('Updated...');
